import * as React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

const PageTop = (props) => {
	
	const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]) // マイナスにすることによって減るスピードが速くなる
  
	return (
		<motion.section id="banner" class={props.classname} style={{opacity: opacity, backgroundColor: 'white'}}>
      <div className="titlebox bgTitle height150">
        <h1>{props.title}</h1>
        <div className="display-block margin-top30">
          {/* <i className="material-icons btn-next cp_anime08"　role="button" onClick={() => scrollTo('#main')}>expand_more</i> */}
          <button type="button" className="material-icons btn-next cp_anime08"　onClick={() => scrollTo('#main')}>expand_more</button>
        </div>
      </div>
    </motion.section>
  )
}
export default PageTop