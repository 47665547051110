import * as React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTop from "../components/pageTop"

const Recruit = () => {

	return (
  	<Layout>
    	<Seo title="佐々畜産の研修生・従業員の募集・講演の受付・牛のことの質問等" />
      <PageTop classname="recruit" title="お問い合わせ窓口" />

      <div id="main" className="recruit">

      <section className="index-section">
          <div className="content viewHeight100">
            <div className="centering-container">
              <div className="centering-box">
                <h2 className="color-h2-text color-h2-border">お問い合わせ一覧</h2>
              </div>
              <p className="margin-bottom10 short-sentence">牛飼い獣医 よろず質問コーナー</p>
                <div className="display-block text-center margin-top5">
                  <button type="button" className="material-icons btn-bg cp_anime08" onClick={() => scrollTo('#yorozu')}>expand_more</button>
                </div>
              
              <p className="margin-bottom10 short-sentence">講習会のご依頼</p>
                <div className="display-block text-center margin-top5">
                  <button type="button" className="material-icons btn-bg cp_anime08" onClick={() => scrollTo('#kousyuu')}>expand_more</button>
                </div>
              
              <p className="margin-bottom10 short-sentence">農場スタッフの募集</p>
                <div className="display-block text-center margin-top5">
                  <button type="button" className="material-icons btn-bg cp_anime08" onClick={() => scrollTo('#bosyuu')}>expand_more</button>
                </div>
              
            </div>
          </div>
        </section>

        <section className="index-section padding-bottom100" id="yorozu">
          <div className="centering-container margin-top50">
            <div className="centering-box">
              <h2 className="color-h2-text color-h2-border">牛飼い獣医 <br className="isShown"/>よろず質問コーナー</h2>
            </div>
            <p className="margin-bottom10 short-sentence">肉用牛に関する様々なご質問をお受けいたします。</p>
            <p className="margin-bottom10 short-sentence">頂いた質問についてはなるべく個別にお答え致しますが、</p>
            <p className="margin-bottom10 short-sentence">「日々のニュース」にて回答させて頂くこともございます。</p>
            <p className="text-center margin-top50"><a className="button btn-sticky" href="https://contact.sassa-chikusan.jp/">ご質問はこちらから</a></p>
          </div>
        </section>

        <section className="index-section padding-bottom100" id="kousyuu">
          <div className="centering-container margin-top50">
            <div className="centering-box">
              <h2 className="color-h2-text color-h2-border">講習会のご依頼</h2>
            </div>

            {/* <p className="margin-bottom10 short-sentence">獣医師として地域の農場を廻りつつ、自身でも農場を営むことで得られた知識と経験があります。</p>
            <p className="margin-bottom10 short-sentence">シェパード勤務時代からの知見に加え、これらを少しでも皆様と共有し、</p>
            <p className="margin-bottom10 short-sentence">この国の畜産分野を盛り立てたいという思いで全国講演活動を行なっています。</p> */}
            <p className="margin-bottom10 short-sentence">シェパード中央家畜診療所時代に得た知見と、</p>
            <p className="margin-bottom10 short-sentence">獣医師として農場を廻りながら<br className="isShown"/>自らも農場を営むということで得る知識と経験。</p>
            <p className="margin-bottom10 short-sentence">これらを皆様と共有し、<br className="isShown"/>日本の畜産分野に貢献したいという思いで</p>
            <p className="margin-bottom10 short-sentence">講演活動を全国で行っております。</p>
            <p className="margin-bottom10 short-sentence">ご興味のある方は、こちらからお問い合わせください。</p>
            <p className="text-center margin-top50"><a className="button btn-sticky" href="https://contact.sassa-chikusan.jp/">お問い合わせはこちらから</a></p>
          </div>
        </section>

        <section className="index-section padding-bottom100" id="bosyuu">
          <div className="centering-container margin-top50">
            <div className="centering-box">
              <h2 className="color-h2-text color-h2-border">農場スタッフの募集</h2>
            </div>
            <table className="margin-center">
              <tr>
                <td>職種</td><td>：正規従業員</td>
              </tr>
              <tr>
                <td>業務内容</td><td>：肉用牛の飼養管理全般</td>
              </tr>
              <tr>
                <td>基本給</td><td>：180,000円</td>
              </tr>
              <tr>
                <td colSpan="2">社会保険完備、昇給あり、賞与あり。</td>
              </tr>
            </table>
            <p className="text-center margin-top50"><a className="button btn-sticky" href="https://contact.sassa-chikusan.jp/">お問い合わせはこちらから</a></p>
            </div>
        </section>

        <section className="index-section padding-bottom100" id="bosyuu">
          <div className="centering-container margin-top50">
            <div className="centering-box">
              <h2 className="color-h2-text color-h2-border">佐々畜産では研修生も<br className="isShown"/>随時募集しております。</h2>
            </div>
            <p className="text-center margin-top50"><a className="button btn-sticky" href="https://contact.sassa-chikusan.jp/">お問い合わせはこちらから</a></p>
          </div>
          
        </section>
      </div>
  	</Layout>
	)
}

export default Recruit
